import type { ImageCTX, ImageOptions, ProviderGetImage } from '@nuxt/image'
import { defu } from 'defu'
import { joinURL, withBase } from 'ufo'

import { createOperationsGenerator } from '#image'

export const operationsGenerator = createOperationsGenerator({
  keyMap: {
    fit: 'rt',
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    quality: 'q',
    format: 'f'
  },
  joinWith: '/',
  /**
   * Some params can contain multiple parameters (colon separated), like:
   * gravity:%type:%x_offset:%y_offset
   */
  formatter: (key: string, value: [] | string) => {
    const PARAMS = Array.isArray(value) ? value.join(':') : value
    return `${key}:${PARAMS}`
  }
})

const base64encode = (string: string): string => {
  if (!string) return ''
  if (import.meta.env.SSR) {
    return Buffer.from(string, 'utf-8').toString('base64')
  }
  return btoa(string)
}

const defaultModifiers = {
  fit: 'auto',
  width: 0,
  height: 0,
  dpr: 1,
  quality: 80
}

export const getImage: ProviderGetImage = (url: string, options: ImageOptions, ctx: ImageCTX) => {
  const isValidDomain = ctx.options.domains.some((domain) => url.includes(domain))
  if (!isValidDomain) return { url }

  const { baseUrl = '/imgproxy', modifiers = {} } = options

  const mergedModifiers = defu(modifiers, defaultModifiers)

  switch (mergedModifiers.fit) {
    case 'cover':
      mergedModifiers.fit = 'fill'
      break
    case 'contain':
      mergedModifiers.fit = 'fit'
      break
    case 'fill':
      mergedModifiers.fit = 'force'
      break
    case 'inside':
      mergedModifiers.fit = 'auto'
      break
    case 'outside':
      mergedModifiers.fit = 'auto'
      break
  }

  const path = joinURL('/_', operationsGenerator(mergedModifiers), base64encode(url))

  return { url: withBase(path, baseUrl) }
}
